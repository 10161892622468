import * as Types from '../../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { ConnectionFragmentFragmentDoc } from '../fragments/ConnectionFragment.generated';
import { MessageFragmentFragmentDoc } from '../fragments/MessageFragment.generated';
import { VisitorFragmentFragmentDoc } from '../fragments/VisitorFragment.generated';
import { ConnectionRestrictionFragmentFragmentDoc } from '../fragments/ConnectionRestrictionFragment.generated';
export type GetConnectionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetConnectionsQuery = { __typename?: 'Query', currentInmate: { __typename?: 'Inmate', id: string, hasSentAnyMessages: boolean, facility: { __typename?: 'Facility', id: string, publicId: string }, connections: Array<{ __typename?: 'Connection', id: string, relationship: Types.ConnectionRelationship, connectionStatus: Types.ConnectionStatus, messages: { __typename?: 'MessageConnection', unreadMessageCount: number }, lastMessage: { __typename?: 'Message', id: string, availability: Types.MessageAvailability, createdAt: number, content: string, isRead: boolean, statusDetails: string | null, sender: { __typename?: 'Inmate', id: string, firstName: string } | { __typename?: 'Visitor', id: string, firstName: string }, connection: { __typename?: 'Connection', id: string } } | null, visitor: { __typename?: 'Visitor', id: string, firstName: string, lastName: string, email: string, profileImageUrl: string | null }, activeRestrictions: Array<{ __typename?: 'ConnectionRestriction', id: string, service: Types.FacilityService, expiresAt: number | null, reason: string, isActive: boolean }>, restrictions: Array<{ __typename?: 'ConnectionRestriction', id: string, service: Types.FacilityService, isActive: boolean }> }> } | null };


export const GetConnectionsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetConnections"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"currentInmate"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"hasSentAnyMessages"}},{"kind":"Field","name":{"kind":"Name","value":"facility"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"publicId"}}]}},{"kind":"Field","name":{"kind":"Name","value":"connections"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ConnectionFragment"}},{"kind":"Field","name":{"kind":"Name","value":"messages"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"unreadMessageCount"}}]}},{"kind":"Field","name":{"kind":"Name","value":"lastMessage"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MessageFragment"}}]}},{"kind":"Field","name":{"kind":"Name","value":"visitor"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"VisitorFragment"}}]}},{"kind":"Field","name":{"kind":"Name","value":"activeRestrictions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ConnectionRestrictionFragment"}}]}}]}}]}}]}},...ConnectionFragmentFragmentDoc.definitions,...MessageFragmentFragmentDoc.definitions,...VisitorFragmentFragmentDoc.definitions,...ConnectionRestrictionFragmentFragmentDoc.definitions]} as unknown as DocumentNode<GetConnectionsQuery, GetConnectionsQueryVariables>;