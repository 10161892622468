import * as Types from '../../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { ConnectionFragmentFragmentDoc } from '../fragments/ConnectionFragment.generated';
import { VisitorFragmentFragmentDoc } from '../fragments/VisitorFragment.generated';
import { ConnectionRestrictionFragmentFragmentDoc } from '../fragments/ConnectionRestrictionFragment.generated';
export type GetVisitorsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetVisitorsQuery = { __typename?: 'Query', currentInmate: { __typename?: 'Inmate', id: string, connections: Array<{ __typename?: 'Connection', id: string, relationship: Types.ConnectionRelationship, connectionStatus: Types.ConnectionStatus, visitor: { __typename?: 'Visitor', id: string, firstName: string, lastName: string, email: string, profileImageUrl: string | null }, activeRestrictions: Array<{ __typename?: 'ConnectionRestriction', id: string, service: Types.FacilityService, expiresAt: number | null, reason: string, isActive: boolean }>, restrictions: Array<{ __typename?: 'ConnectionRestriction', id: string, service: Types.FacilityService, isActive: boolean }> }> } | null };


export const GetVisitorsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetVisitors"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"currentInmate"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"connections"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ConnectionFragment"}},{"kind":"Field","name":{"kind":"Name","value":"visitor"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"VisitorFragment"}}]}},{"kind":"Field","name":{"kind":"Name","value":"activeRestrictions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ConnectionRestrictionFragment"}}]}}]}}]}}]}},...ConnectionFragmentFragmentDoc.definitions,...VisitorFragmentFragmentDoc.definitions,...ConnectionRestrictionFragmentFragmentDoc.definitions]} as unknown as DocumentNode<GetVisitorsQuery, GetVisitorsQueryVariables>;